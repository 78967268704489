import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AuthorBanner = makeShortcode("AuthorBanner");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner" />
    <p>&nbsp;</p>
    <h3>Experience</h3>
    <p>COGNIGY, REMOTE (MAY 2022 - PRESENT)</p>
    <ul>
      <li parentName="ul">{`Work on the frontend of Cognigy AI application. `}</li>
      <li parentName="ul">{`Use technologies such as React, TypeScript, Redux, and Cypress to write type-safe and tested code.`}</li>
      <li parentName="ul">{`Collaborate closely with designers and product managers to deliver features.`}</li>
      <li parentName="ul">{`Present features I build every sprint.`}</li>
      <li parentName="ul">{`Conduct code reviews daily.`}</li>
    </ul>
    <p>&nbsp;</p>
    <p>AHREFS, REMOTE (MAR 2021 - MAY 2022)</p>
    <ul>
      <li parentName="ul">{`Independently translated Figma design mock-ups into live user interfaces using React and CSS.`}</li>
      <li parentName="ul">{`Interacted with Backend APIs to fetch data. `}</li>
      <li parentName="ul">{`Worked in an environment of keen code reviews and testing.`}</li>
      <li parentName="ul">{`Collaborated with designers to implement and maintain accessible components in a design system used across the company's teams.`}</li>
      <li parentName="ul">{`Implemented `}<a parentName="li" {...{
          "href": "https://ahrefs.com/vs"
        }}>{`pages`}</a>{` on a Gatsby static site.`}</li>
      <li parentName="ul">{`Contributed to open source projects.`}
        <p>&nbsp;</p>
      </li>
    </ul>
    <h3>Posts and Articles</h3> 
    <FeedItems itemsFormat="compact" filterByTags={['post', 'article']} limit={5} mdxType="FeedItems" />
    <p>&nbsp;</p>
    <h3>Projects</h3> 
    <FeedItems itemsFormat="compact" filterByTags={['project']} mdxType="FeedItems" />
    <p>&nbsp;</p>
    <h3>Open source</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/giraud/bs-css"
      }}>{`bs-css:`}</a>{` Statically typed CSS in ReasonML. I implemented support for multiple CSS properties.`}</p>
    <p>&nbsp;</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      